import moment from 'moment';
import { FinnishSSN } from 'finnish-ssn';
import { FINNISH_DATE_FORMAT } from "./DixuConstants";
export const isValidSsn = function () {
  let hetu = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return FinnishSSN.validate(hetu);
};

// Chars 0->5 are birthdate in DDMMYY format eg 010190 => 1.1.1990
const extractDate = hetu => {
  if (!FinnishSSN.validate(hetu)) {
    return '';
  }
  const {
    dateOfBirth
  } = FinnishSSN.parse(hetu);
  return dateOfBirth;
};
export const birthDateFromSsn = hetu => isValidSsn(hetu) && moment(extractDate(hetu)).format(FINNISH_DATE_FORMAT);